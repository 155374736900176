import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, ListGroup, InputGroup } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'
import Editor from './Editor'


class Productadd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("TextDraft", { id }).then((res) => {
            this.setState({
                title: res.data.title,
                target_request: res.data.target_request,
                brief: res.data.brief,
                preview_image: res.data.preview_image,
                content: res.data.content,
            })
        })
    }

    submitImage() {
        return new Promise(resolve => {
            // console.log(this.state.selectedFile);
            if (!this.state.selectedFile)
                return resolve()
            const reader = new FileReader()
            reader.onload = (e) => {
                resolve(e.target.result)
                // resolve(`data:${this.state.selectedFile.type};base64,${Buffer.from(e.target.result).toString('base64')}`)
            };
            reader.readAsDataURL(this.state.selectedFile)
        })
    }


    submit() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        this.setState({ loading: true }, () => {
            const error = {}
            if (Object.keys(error).length === 0) {
                this.submitImage().then(image => {
                    dataProvider.customs.saveDraft({
                        id,
                        image,
                        content: this.state.content,
                    }).then(() => window.location.reload())
                })
            } else {
                this.setState({ error, loading: false })
            }
        })
    }

    validate() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        this.setState({ loading: true }, () => {
            const error = {}
            if (Object.keys(error).length === 0) {
                dataProvider.customs.validateDraft({
                    id,
                    content: this.state.content,
                }).then(() => window.location = '/validated-view?id=' + id)
            } else {
                this.setState({ error, loading: false })
            }
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/texts">Drafts</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Edit Draft</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/texts" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">Edit Draft</h4>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <ListGroup as="ul" className="list-group-flush">
                                    <ListGroup.Item as="li" className="p-3">
                                        <h5 className="font-weight-bold pb-2">Text Info</h5>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-0">
                                                <tbody>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Title
                                                        </td>
                                                        <td>
                                                            {this.state.title}
                                                        </td>
                                                    </tr>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Request
                                                        </td>
                                                        <td>
                                                            {this.state.target_request}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                            {
                                this.state.brief && (
                                    <Card>
                                        <div style={{ padding: "10px" }}>
                                            <h6 style={{ marginBottom: "10px" }}>Brief</h6>
                                            <div dangerouslySetInnerHTML={{ __html: `<p>${this.state.brief.split('\n').join('<br>')}</p>` }} />
                                        </div>
                                    </Card>
                                )
                            }
                            <Card>
                                <Card.Body>
                                    <h5 className="font-weight-bold pb-2">Image Preview</h5>
                                    <Form className="row g-3">
                                        <div className="col-md-6 mb-3">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile04"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    value={null}
                                                    onChange={(e) => this.setState({ selectedFile: e.target.files[0] })}
                                                />
                                                <label className="custom-file-label" htmlFor="inputGroupFile04">
                                                    Choose file
                                                </label>
                                            </div>
                                            {
                                                this.state.selectedFile && (
                                                    <div style={{ display: "flex", marginTop: "10px" }}>
                                                        <h6>{this.state.selectedFile.name}</h6>
                                                        <div style={{ cursor: "pointer" }} onClick={() => this.setState({ selectedFile: null })}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Form>
                                    <div>
                                        <img src={this.state.preview_image} />
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Body>
                                        <Editor defaultValue={this.state.content} onEditorChange={(e) => this.state.content = e} />
                                    </Card.Body>
                                    <Card.Body>
                                        <InputGroup className="mb-4">
                                            <InputGroup.Prepend>
                                                <InputGroup.Checkbox aria-label="I certify that the text is neither duplicated nor translated and that the text is opimized by h2, h3, ..." onChange={() => this.setState({ certified: !this.state.certified })} />
                                            </InputGroup.Prepend>
                                            <Form.Control type="text" placeholder="I certify that the text is neither duplicated nor translated and that the text is opimized by h2, h3, ..." />
                                        </InputGroup>
                                    </Card.Body>
                                </Card.Body>
                                <Card.Body>
                                    <div className="d-flex justify-content-end mt-3">
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" onClick={() => this.submit()}>
                                                    Save
                                                </Button>
                                            )
                                        }
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" disabled={!this.state.certified} style={{ marginLeft: "20px" }} onClick={() => this.validate()}>
                                                    Validate
                                                </Button>
                                            )
                                        }
                                    </div>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Productadd;