/* TRANSLATED */

import React, { Component } from 'react';
import dataProvider from '../../../dataProvider';
import { t } from '../../../t'


class SuccessPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    const session_id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).session_id
    const hash = Object.fromEntries((new URLSearchParams(window.location.search).entries())).hash

    console.log({ session_id, hash })

    setTimeout(() => {
      dataProvider.customs.successBuy(session_id, hash)
    }, 2000)
  }

  render() {
    return (
      <div style={{
        width: "100%",
        textAlign: "center"
      }}>
        <h1>{t("Merci pour votre achat")}</h1>
        <p>
          {t("Vous allez être redirigé.")}
        </p>
        <p>
          {t("Pour toute question, n'hésitez à nous contacter par email.")}
        </p>
      </div>
    )
  }
}

export default SuccessPage;
