import React, { Component } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'
import Editor from './Editor'


class Productadd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }

        this.categories = [
            "Animaux",
            "Art & Culture",
            "Avocat & Juridique",
            "Beauté",
            "Code Promo",
            "Croyance & Voyance",
            "Entreprise",
            "Fêtes & Cérémonies",
            "Finance & Banque",
            "Cuisine & Gastronomie",
            "Généraliste",
            "Écologie",
            "Hightech & Informatique",
            "Immo & Démenagement",
            "Jeux d'argent",
            "Loisir créatif",
            "Maison & Jardin",
            "Mode",
            "Parentalité",
            "Rencontre",
            "Sexe",
            "Retraite",
            "Santé",
            "Science",
            "Sport & Loisir",
            "Tabac",
            "Transport",
            "Travaux & Dépannage",
            "Voyage & Hôtellerie",
            "Non classé",
        ]
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("Post", { id }).then((res) => {
            this.setState({
                title: res.data.title,
                category: res.data.category,
                count_words: res.data.count_words,
                scoreYTG: res.data.scoreYTG,
                spam_score: res.data.spam_score,
                price: res.data.price,
                content: res.data.content,
                score_NextLevel: res.data.score_NextLevel,
                // score_Linksgarden: res.data.score_Linksgarden,
                score_Discover: res.data.score_Discover,
                score_Amazon: res.data.score_Amazon,
            })
        })
    }

    submit() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        this.setState({ loading: true }, () => {
            const error = {}
            if (!this.state.title)
                error.title = true
            if (!this.state.category)
                error.category = true
            if (!this.state.count_words)
                error.count_words = true
            if (!this.state.scoreYTG)
                error.scoreYTG = true
            if (!this.state.spam_score)
                error.spam_score = true
            if (!this.state.price)
                error.price = true
            if (!this.state.content)
                error.content = true
            if (!this.state.score_NextLevel)
                error.score_NextLevel = true
            // if (!this.state.score_Linksgarden)
            //     error.score_Linksgarden = true
            if (!this.state.score_Discover)
                error.score_Discover = true
            if (!this.state.score_Amazon)
                error.score_Amazon = true
            if (Object.keys(error).length === 0) {
                dataProvider.update('Post', {
                    data: {
                        id,
                        title: this.state.title,
                        category: this.state.category,
                        count_words: this.state.count_words,
                        scoreYTG: this.state.scoreYTG,
                        spam_score: this.state.spam_score,
                        price: this.state.price,
                        content: this.state.content,
                        score_NextLevel: this.state.score_NextLevel,
                        // score_Linksgarden: this.state.score_Linksgarden,
                        score_Discover: this.state.score_Discover,
                        score_Amazon: this.state.score_Amazon,
                    }
                }).then(() => window.location = '/posts')
            } else {
                this.setState({ error })
            }
        })
    }

    delete() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        this.setState({ loading: true }, () => {
            dataProvider.delete('Post', {
                id
            }).then(() => window.location = '/posts')
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/posts">Posts</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Edit Post</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/posts" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">Edit Post</h4>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                                    <Form className="row g-3">
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Title *</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Title" defaultValue={this.state.title} onChange={e => this.setState({ title: e.target.value })} style={this.state.error && this.state.error.title ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Category *</Form.Label>
                                            {/* <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Category" defaultValue={this.state.category} onChange={e => this.setState({ category: e.target.value })} style={this.state.error && this.state.error.category ? { border: "1px solid red" } : {}} /> */}
                                            <select className="form-control choicesjs" id="validationServer01" placeholder="Category2" onChange={e => this.setState({ category: e.target.value })} style={this.state.error && this.state.error.category ? { border: "1px solid red" } : {}}>
                                                <option value="" selected>Select Category</option>
                                                {
                                                    this.categories.map(e => <option value={e}>{e}</option>)
                                                }
                                            </select>

                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Count Words *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Count World" defaultValue={this.state.count_words} onChange={e => this.setState({ count_words: e.target.value })} style={this.state.error && this.state.error.count_words ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Score YTG *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score YTG" defaultValue={this.state.scoreYTG} onChange={e => this.setState({ scoreYTG: e.target.value })} style={this.state.error && this.state.error.scoreYTG ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Spam Score *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Spam Score" defaultValue={this.state.spam_score} onChange={e => this.setState({ spam_score: e.target.value })} style={this.state.error && this.state.error.spam_score ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Price *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Price" defaultValue={this.state.price} onChange={e => this.setState({ price: e.target.value })} style={this.state.error && this.state.error.price ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Score NextLevel *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score NextLevel" defaultValue={this.state.score_NextLevel} onChange={e => this.setState({ score_NextLevel: e.target.value })} style={this.state.error && this.state.error.score_NextLevel ? { border: "1px solid red" } : {}} />
                                        </div>
                                        {/* <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Score Linksgarden *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score Linksgarden" defaultValue={this.state.score_Linksgarden} onChange={e => this.setState({ score_Linksgarden: e.target.value })} style={this.state.error && this.state.error.score_Linksgarden ? { border: "1px solid red" } : {}} />
                                        </div> */}
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Score Discover *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score Discover" defaultValue={this.state.score_Discover} onChange={e => this.setState({ score_Discover: e.target.value })} style={this.state.error && this.state.error.score_Discover ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Score Amazon *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score Amazon" defaultValue={this.state.score_Amazon} onChange={e => this.setState({ score_Amazon: e.target.value })} style={this.state.error && this.state.error.score_Amazon ? { border: "1px solid red" } : {}} />
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <h5 className="font-weight-bold mb-3">Content</h5>
                                    <Card.Body>
                                        <Editor defaultValue={this.state.content} onEditorChange={(e) => this.state.content = e} />
                                    </Card.Body>
                                </Card.Body>
                                <Card.Body>
                                    <div className="d-flex justify-content-end mt-3">
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-secondary" style={{ marginRight: "20px" }} onClick={() => this.delete()}>
                                                    Delete
                                                </Button>
                                            )
                                        }
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" onClick={() => this.submit()}>
                                                    Edit Post
                                                </Button>
                                            )
                                        }
                                    </div>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Productadd;