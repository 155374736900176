import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, ProgressBar } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'
import { t } from '../../../../t'

const csvtojson = require("csvtojson")
const { convertArrayToCSV } = require('convert-array-to-csv');


class Productadd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            selectedFile: null,
            step: 1,
        }
    }

    componentDidMount() {
        dataProvider.customs.profile().then((response) => {
            this.setState({ profile: response.data })
        })
    }

    promiseCreate(json) {
        return new Promise(resolve => {
            dataProvider.customs.callWebhook(this.state.profile.webhook_hash, {
                title: json.title,
                category: json.category,
                scoreYTG: json.scoreYTG ? parseInt(json.scoreYTG) : 0,
                spam_score: json.spam_score ? parseInt(json.spam_score) : 0,
                amount_in_credits: json.amount_in_credits ? parseInt(json.amount_in_credits) : 0,
                content: json.content,
                preview_image: json.preview_image,
                NextLevel: json.NextLevel ? parseInt(json.NextLevel) : 0,
                Discover: json.Discover ? parseInt(json.Discover) : 0,
                Amazon: json.Amazon ? parseInt(json.Amazon) : 0,
                tag_human_robot: json.tag_human_robot
            }).then((data) => resolve(data))
        })
    }

    submit() {
        console.log(this.state.selectedFile);
        const reader = new FileReader()
        reader.onload = (e) => {
            csvtojson().fromString(e.target.result).then((json) => {
                console.log(json)
                this.setState({ step: 2, json }, async () => {
                    const a = (per) => new Promise(resolve => this.setState({ per }, () => resolve()))
                    for (let i = 0; i < this.state.json.length; i++) {
                        this.state.json[i].result = "Skipped"
                        try {
                            const result = await this.promiseCreate(this.state.json[i])
                            this.state.json[i].result = result.id ? 'Uploaded: id = ' + result.id: `Not uploaded: (${result.code}) ` + result.message
                        } catch (e) {
                            console.log(e)
                            this.state.json[i].result = "Server Error"
                        }
                        await a(Math.floor(100 * (i + 1) / this.state.json.length))
                    }
                    this.setState({ step: 3 })
                })
            })
        };
        reader.readAsText(this.state.selectedFile)
    }

    downloadSamples() {
        const csv = `title,category,scoreYTG,spam_score,amount_in_credits,content,preview_image,NextLevel,Discover,Amazon,tag_human_robot\nExemple,Entreprise,0,0,1,"<p>Ceci est un test pour le CSV</p><p>Ce text est <strong>strong</strong></p><p>Merci</p><p>&nbsp;</p>","data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABHNCSVQICAgIfAhkiAAAA0BJREFUWIXtmF1sFFUUgL87O91u/6SWpi5dIlTZmGBEqjYaW14qFROMQgOJPwF8MEQeMDExQX3xRY0PGhONMRLgQYGaqlCMUEJtNAWNJmrrC1haAk1tdfnpbrvttLPd3uODNCVqZzqzu4SQ/ZJ5uuee+ebMvXdyBvLc5KiFBj5+aEODFtmIUhEl4j7PMFi36ok0ova/uOK59pwJbm7dHJwwU/sUPKOUWvADlRdXsGXNC9jTqfS5v05vfemu7S1+BA23AMu03zeUetaLHMBseGFB0LwzvPKTd3s/firrguu/WB8VeN5P4mspLAia0fDdn77Xu/tpr3MdBUWZTYYyAv7V5pitpFdJ51espDIjq38xJ/nRgiUdBQ33JeqZfyTvWXAls29wFRGZd8zLxsmZ4OhknFErMe/47MZ5veedBqc8Oa3g17+2MnjlPBNTSSbs8f+5xsw/Rs41OuUxcyUIMDJxmbafnc9nQVz2wQ1OXjBT8oKZkhfMlLxgpuQFM8XXtzhcEqbp9keoCFXQl+jn2Pl2GiL13FdVi2mYnBr6HkMZREojfNl3iMWhxWyMPsnBMy1Y6UlP9/JcwSIzxK66l6mtWk3MinFrYTk1i2rYtnILMStGz6XfCKgAS0rCPLb8UZaWLmXd8iZqq1bjocv1L1iz6A7KgmV0Dn7LZ72tHPi9hWQqyWR6ioZIPXW3PcC0nua7wS5mZIa1yxp5aMmD/DD8I1bayr3gqJ1ARIiWr6CyqJLqkmoSdoI3fnqLD7o/pLigmOboBuJ2nF9i3dRXP0zIDPHNQKdnOfCxBofGh+kY6GTtskbeXvMmw+N/crj/CDvu3Y6VnqQoEOJw/xEAOgY6qAvfT/fFHmJW7PoIArSe/ZzjF45TFryFS9ZFUnqaV06+RmlBKXE7QTKVBODC2AC7ul4lmRr3JecqqNHzdnZjqSRjV0UARqbijEzF/xMXt+fvSwDEqbvCbQ2Kuuw4ngUUcsVp3FFQSbpDi9bZVZpDi9aawAmnGEfBo5uO9ilhT3a1rkHU3vbmtrNOIa7HTPFM4U4RfdBtrXhCI6J1iy6yd7qFevqBKUgzQgTl45MAIAiKIdHSdmzTV12+cuS52fgb3gY7eWN4ciMAAAAASUVORK5CYII=",0,0,0,human`
        const csvFile = 'posts.csv'
        const url = window.URL.createObjectURL(
            new Blob([csv]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            csvFile
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    downloadResult() {
        const csv = convertArrayToCSV(this.state.json)
        const resultFile = 'posts-results.csv'
        const url = window.URL.createObjectURL(
            new Blob([csv]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            resultFile
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    render() {
        if (!this.state.profile)
            return <></>
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/posts">{t("Posts")}</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{t("Imports Posts")}</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/posts" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">{t("Back")}</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">{t("Imports Posts")}</h4>
                        </Col>
                        <Col lg="12">
                            {
                                this.state.step === 1 && (
                                    <Card>
                                        <Card.Body>
                                            {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                                            <Form className="row g-3">
                                                <div className="col-md-6 mb-3">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="inputGroupFile04"
                                                            accept='.csv'
                                                            value={null}
                                                            onChange={(e) => this.setState({ selectedFile: e.target.files[0] })}
                                                        />
                                                        <label className="custom-file-label" htmlFor="inputGroupFile04">
                                                            {t("Choose file")}
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.selectedFile && (
                                                            <div style={{ display: "flex", marginTop: "10px" }}>
                                                                <h6>{this.state.selectedFile.name}</h6>
                                                                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ selectedFile: null })}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div>

                                                    </div>
                                                </div>
                                            </Form>
                                        </Card.Body>
                                        <Card.Body>
                                            <div className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" style={{ marginRight: "20px" }} onClick={() => this.downloadSamples()}>
                                                    {t("Download Samples")}
                                                </Button>
                                                {
                                                    !this.state.loading && (
                                                        <Button variant="btn btn-primary" disabled={!this.state.selectedFile} onClick={() => this.submit()}>
                                                            {t("Import Posts")}
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            }
                            {
                                this.state.step === 2 && (
                                    <Card>
                                        <Card.Body>
                                            <ProgressBar className="mb-3" now={this.state.per} label={`${this.state.per}%`} style={{}} />
                                        </Card.Body>
                                    </Card>
                                )
                            }
                            {
                                this.state.step === 3 && (
                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" style={{ marginRight: "20px" }} onClick={() => this.downloadResult()}>
                                                    {t("Download result")}
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            }

                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Productadd;


