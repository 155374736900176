import React, { Component, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default class App extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {

    }

    render() {

        // const editorRef = useRef(null);
        // const log = () => {
        //     if (editorRef.current) {
        //         console.log(editorRef.current.getContent());
        //     }
        // };
        let _editor = null
        return (
            <>
                <Editor
                    onInit={(evt, editor) => _editor = editor}
                    initialValue={this.props.defaultValue ||  "<p></p>"}
                    onEditorChange={e => this.props.onEditorChange(e)}
                    init={{
                        height: 500,
                        menubar: false,
                        // plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        // ],
                        plugins: [
                            'advlist',
                            'lists',
                            'image',
                            'charmap',
                            'print',
                            'preview',
                            'anchor',
                            'searchreplace',
                            'visualblocks',
                            'code',
                            'fullscreen',
                            'insertdatetime',
                            'media',
                            'table',
                            'paste',
                            'code',
                            'help',
                            'wordcount',
                            'image'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | link image | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                        automatic_uploads: true,

                        file_picker_types: 'image',
                        file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.onchange = function () {
                                var file = this.files[0];

                                var reader = new FileReader();
                                reader.onload = function () {
                                    var id = 'blobid' + (new Date()).getTime();
                                    var blobCache = _editor.editorUpload.blobCache;
                                    var base64 = reader.result.split(',')[1];
                                    var blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);

                                    cb(blobInfo.blobUri(), { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            };

                            input.click();
                        },

                    }}


                />
                {/* <button onClick={log}>Log editor content</button> */}
            </>
        );
    }

}