const { default: dataProvider } = require("./dataProvider")

const fr = {
    "1 Crédits ~ 1€": "1 Crédits = 1€",
    "12GB RAM, 256GB Storage": "12GB RAM, 256GB d'espace de stockage",
    "Spam Score": "Spam Score",
    "Score YTG": "Score YTG",
    "Change Password": "Changer le mot de passe",
    "Password": "Mot de passe",
    "Merci pour votre achat": "Merci pour votre achat",
    "Vous allez être redirigé.": "Vous allez être redirigé.",
    "Pour toute question, n'hésitez à nous contacter par email.": "Pour toute question, n'hésitez pas à nous contacter par email.",
    "Blue": "Bleu",
    "Black": "Le noir",
    "Cart": "Chariot",
    "Title": "Titre",
    "Category": "Catégorie",
    "Count words": "Nombre de mots",
    "Words": "Mots",
    "Price": "Prix",
    "Published": "Publié",
    "Intented": "En attente de publication",
    "To Publish": "A publier",
    "Failed Link": "Échec du lien",
    "Not link": "Pas de lien",
    "elem, link": "élément, lien",
    "Posts": "Articles",
    "Post View": "Affichage de la publication",
    "Back": "Retour",
    "Count Word": "Nombre de mots",
    "Websites": "Sites Internet",
    "Content": "Contenu",
    "Preview": "Aperçu",
    "Website List": "Liste de sites Web",
    "How to link your site to webapp ? Click here": "Comment lier votre site à une webapp ? Cliquez ici",
    "How to link your site to webapp ?": "Comment lier votre site à une webapp ?",
    "Click here": "Cliquez ici",
    "Website": "Site Internet",
    "Websites List": "Liste des sites Web",
    "Name": "Nom",
    "Url": "URL",
    "Connection": "Connexion",
    "New Website": "Nouveau site Web",
    "URL": "URL",
    "Download Plugin": "Télécharger le plugin",
    "You have to install our Plugin": "Vous devez installer notre plugin",
    "You have to install our": "Vous devez installer notre",
    "Plugin": "Plugin",
    "Add Website": "Ajouter un site Web",
    "Edit Website": "Modifier le site Web",
    "Website Details": "Détails du site Web",
    "Website Info": "Informations sur le site Web",
    "Posts List": "Liste des publications",
    "Buy Credits": "Acheter",
    "Purchase": "Acheter",
    "credits": "crédits",
    "My Profile": "Mon profil",
    "Invoices": "Factures",
    "Logout": "Se déconnecter",
    "Order": "Commandes",
    "Success !": "Succès !",
    "A email has been send to . Please check for an": "Un e-mail a été envoyé à . Veuillez vérifier un",
    "email from company and click": "e-mail de l'entreprise et cliquez sur",
    "on the included link to reset your password.": "sur le lien inclus pour réinitialiser votre mot de passe.",
    "Resend the mail": "Renvoyer le courrier",
    "Reset Password": "Réinitialiser le mot de passe",
    "You can reset your password here": "Vous pouvez réinitialiser votre mot de passe ici",
    "Email": "E-mail",
    "Sign In": "S'identifier",
    "Forgot Password?": "Mot de passe oublié ?",
    "Getting Started": "Inscription",
    "Username": "Nom d'utilisateur",
    "Do you have an account? Sign In": "Avez-vous un compte? S'identifier",
    "Overview": "Aperçu",
    "Post": "Article",
    "Product": "Produit",
    "Select Category": "Choisir une catégorie",
    "Caractéristique": "Caractéristique",
    "All Invoice": "Toutes les factures",
    "Invoices List": "Liste des factures",
    "Amount Paid": "Le montant payé",
    "Date": "Date",
    "Basic": "De base",
    "Standard": "Standard",
    "Platinum": "Platine",
    "Premium": "Prime",
    "Credits": "Crédits",
    "Publication": "Publication",
    "View": "Voir",
    "Link to website": "Envoyer sur un site",
    "Create Account": "Créer un compte",
    "Do you have an account?": "Vous n'avez pas de compte",
    "Published Date": "Date de publication",
    "Status": "Status",
    "Enter Username": "Rentrer Nom d'utilisateur",
    "Enter Email": "Rentrer Email",
    "Enter Password": "Rentrer Mot De Passe",
    "Log In": "S'identifier",
    "Sign Up": "S'inscrire",
    "Don't have an account?": "Vous n'avez pas de compte ?",
    "Search Product": "Rechercher",
    "Search Website": "Rechercher",
    "Create Website": "Cérer site web",
    "API KEY": "CLE API",
    "Delete": "Supprimer",
    "Update": "Mettre à jour",
    "Test connection": "Tester la connexion",
    "Enter API KEY": "Rentrer CLE API",
    "Success connection": "Connexion réussie",
    "Error connection": "Erreur de connexion",
    "Enter URL": "Rentrer URL",
    "Configurate Publication": "Configurer la publication",
    "Publication Date": "Date de publication",
    "Submit": "Envoyer",
    "Close": "Fermer",
    "Select Websites": "Selectionner site web",
    "Load categories From Website": "Télécharger les categories",
    "Load Authors From Website": "Télécharger les auteurs",
    "Author": "Auteur",
    "Select Author": "Selectionner un auteur",

    "Test Connection": "Tester la connexion",
    "Edit": "Editer",
    "Search URL": "Chercher",
    "Interval": "Intervale",
    "0 day": "0 jour",
    "1 day": "1 jour",
    "2 day": "2 jours",
    "3 day": "3 jours",
    "4 day": "4 jours",
    "5 day": "5 jours",
    "6 day": "6 jours",
    "7 day": "7 jours",
    "14 day": "14 jours",
    "30 day": "30 jours",
    "60 day": "60 jours",
    "90 day": "90 jours",
    "Not config": "Non configuré",
    "Not Supported": "Non Supporté",
    "Missing Cred": "Crédentials manquant",
    "Not Connected": "Non connecté",
    'Failed': "KO",
    "Congured": "Configué",
    "Connected": "Connecté",
    "Error occured": "Une erreur est survenu",
    "Linked": "Lié",
    "Article présent dans un autre panier": "Article présent dans un autre panier",
    "New Password": "Nouveau mot de passe",
    "Repeat Password": "Répéter le mot de passe",
    "Send": "Envoyer",
    "Reload categories & author": "Recharger les categories et les auteurs",
    "Select Website": "Séléctionner un site web",
    "Indexation": "Indexation",
    "Forcer l'indexation": "Forcer l'indexation",
    "Score d'optimisiation & Danger SEO": "Score d'optimisiation & Danger SEO",
    "Nombre de crédits souhaités": "Nombre de crédits souhaités",
    "La clé API est automatiquement sauvegardé": "La clé API est automatiquement sauvegardé",
    "Categories Found": "Catégories trouvées",
    "Authors Found": "Auteurs trouvées",
    "Loading...": "En cours...",
    "Buy": "Acheter",
    "Cette action coute 1 credit": "Cette action coute 1 crédit",
    "Présentation": "Présentation",
    "Search Post": "Rechercher",
    "Bought": "Acheté ?",
    "Post Details": "Détails",
    "Post Info": "Information",
    "Count Words": "Nombre de mots",
    "Score NextLevel": "Répond a un besoin sur NextLevel",
    "Score Discover": "Optimisé pour Discover",
    "Score Amazon": "Adapté pour Amazon",
    "Preview Image": "Image de présentation",
    "Webhook": "Webhook",
    "Purchase Callback": "Callback d'achat",
    "Crédits Gagnés": "Crédits Gagnés",
    "Bradigo vous doit encore": "Bradigo vous doit encore",
    "Import Via CSV": "Importer Via CSV",
    "Imports Posts": "Importer des articles",
    "Choose file": "Choisir un CSV",
    "Choose Image": "Choisir une image (10Ko max)",
    "Import Posts": "Importer des articles",
    "Download Samples": "Télécharger un exemple de CSV",
    "Download result" : "Télécharger résultat",
    "Publié" : "Publié",
    "Acheté" : "Acheté",

    "Répond a un besoin sur NextLevel": "Répond a un besoin sur NextLevel",
    "Répond a un besoin sur Linksgarden": "Répond a un besoin sur Linksgarden",
    "Optimisé pour Discover": "Optimisé pour Discover",
    "Adapté pour Amazon": "Adapté pour Amazon",
    "Contient minimum 1 image": "Contient minimum 1 image",
    "Faire une demande de paiement": "Faire une demande de paiement"
}

const t = (key) => {
    if (!fr[key]) {
        dataProvider.customs.postMissingTranslation(key)
        return 'MISSING TRANSLATION'
    }
    return fr[key]
}

module.exports = {
    fr,
    t
}
