import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tab, Nav, Tooltip } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'

import dataProvider from '../../../../dataProvider'


const formatDate = (d) => {
    d = d.split('T')[0]
    const arr = d.split('-')
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}

class Post extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 1000,
            page: 1,
            field: null,
            order: null,
            filterTitle: '',

            draft_limit: 1000,
            draft_page: 1,
            draft_field: null,
            draft_order: null,
            draft_filterTitle: '',

            validated_limit: 1000,
            validated_page: 1,
            validated_field: null,
            validated_order: null,
            validated_filterTitle: '',

        }
    }

    componentDidMount() {
        this.call()
    }

    call() {
        dataProvider.getList('TextVirgin', { pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: { title: this.state.filterTitle } })
            .then(response => this.setState({ data: response.data, total: response.total }))
        dataProvider.getList('TextDraft', { pagination: { page: this.state.draft_page, perPage: this.state.draft_limit }, sort: { field: this.state.draft_field, order: this.state.draft_order }, filter: { title: this.state.draft_filterTitle } })
            .then(response => this.setState({ draft_data: response.data, draft_total: response.total }))
        dataProvider.getList('TextValidated', { pagination: { page: this.state.validated_page, perPage: this.state.validated_limit }, sort: { field: this.state.validated_field, order: this.state.validated_order }, filter: { title: this.state.validated_filterTitle } })
            .then(response => this.setState({ validated_data: response.data, validated_total: response.total }))
    }

    renderFirst() {
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        return (
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="font-weight-bold">Texts</h4>
                    </div>
                    <div className="create-workform">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="modal-post-search d-flex">
                                <Form className="mr-3 position-relative">
                                    <div className="form-group mb-0">
                                        <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Post" onChange={(e) => this.setState({ filterTitle: e.target.value }, () => this.call())} />
                                    </div>
                                </Form>
                                {/* <Link to="/text-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Add Text
                                </Link>
                                <Link to="/text-import" className="btn btn-primary position-relative d-flex align-items-center justify-content-between" style={{ marginLeft: "20px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Import Texts
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Texts List</h5>
                                </div>
                                <div className="table-responsive">
                                    <table className="table data-table mb-0">
                                        <thead className="table-color-heading">
                                            <tr className="text-light">
                                                <th scope="col" style={{ cursor: "pointer" }} onClick={sort("title")}>
                                                    <label className="text-muted m-0" style={{ cursor: "pointer" }} >Title</label>
                                                    {' '}
                                                    {this.state.field === "title" && this.state.order === 'desc' && (down)}
                                                    {this.state.field === "title" && this.state.order === 'asc' && (up)}
                                                    {/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="Search Post">  */}
                                                </th>
                                                <th scope="col">
                                                    <label className="text-muted mb-0" >Request</label>
                                                </th>
                                                <th scope="col" style={{ cursor: "pointer" }} onClick={sort("createdAt")}>
                                                    <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Date</label>
                                                    {' '}
                                                    {this.state.field === "createdAt" && this.state.order === 'desc' && (down)}
                                                    {this.state.field === "createdAt" && this.state.order === 'asc' && (up)}
                                                </th>
                                                {/* <th scope="col">
                                                </th> */}
                                                <th scope="col">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.map((item) => (
                                                    <tr key={item.Price} className="white-space-no-wrap">
                                                        <td className="">
                                                            <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                {/* <div className="h-avatar is-medium">
                                                                <img className="avatar rounded" alt="user-icon" src={item.post} />
                                                            </div> */}
                                                                <div className="data-content">
                                                                    <div>
                                                                        <span className="font-weight-bold">{item.title}</span>
                                                                    </div>
                                                                    {/* <p className="m-0 mt-1">
                                                                    {item.des}
                                                                </p> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.target_request}
                                                        </td>
                                                        <td>
                                                            {formatDate(item.createdAt)}
                                                        </td>
                                                        {/* <td>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                    <Link to={"/text-edit?id=" + item.id} className="">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                        </svg>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td> */}
                                                        <td>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                                    <Link to={"/text-view?id=" + item.id} className="">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                        </svg>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* <div>
                        {
                            this.state.page > 1 && (
                                <>
                                    <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ page: this.state.page - 1 }, () => this.call())}>
                                        {this.state.page - 1}
                                    </span>
                                    {' '}
                                    {'-'}
                                    {' '}
                                </>
                            )
                        }
                        <span>
                            {this.state.page}
                        </span>
                        {
                            this.state.total > this.state.page * this.state.limit && (
                                <>
                                    {' '}
                                    {'-'}
                                    {' '}
                                    <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ page: this.state.page + 1 }, () => this.call())}>
                                        {this.state.page + 1}
                                    </span>
                                </>

                            )
                        }
                        {' '}
                        <span>
                            ({this.state.total % this.state.limit === 0 ? this.state.total / this.state.limit : Math.floor(this.state.total / this.state.limit) + 1})
                        </span>
                    </div> */}
                </Row>
            </Col>

        )
    }

    renderSecond() {
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        return (
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="font-weight-bold">Drafts</h4>
                    </div>
                    <div className="create-workform">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="modal-post-search d-flex">
                                <Form className="mr-3 position-relative">
                                    <div className="form-group mb-0">
                                        <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Post" onChange={(e) => this.setState({ draft_filterTitle: e.target.value }, () => this.call())} />
                                    </div>
                                </Form>
                                {/* <Link to="/text-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Add Text
                                </Link>
                                <Link to="/text-import" className="btn btn-primary position-relative d-flex align-items-center justify-content-between" style={{ marginLeft: "20px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Import Texts
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Drafts List</h5>
                                </div>
                                <div className="table-responsive">
                                    <table className="table data-table mb-0">
                                        <thead className="table-color-heading">
                                            <tr className="text-light">
                                                <th scope="col" style={{ cursor: "pointer" }} onClick={sort("title")}>
                                                    <label className="text-muted m-0" style={{ cursor: "pointer" }} >Title</label>
                                                    {' '}
                                                    {this.state.field === "title" && this.state.order === 'desc' && (down)}
                                                    {this.state.field === "title" && this.state.order === 'asc' && (up)}
                                                    {/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="Search Post">  */}
                                                </th>
                                                <th scope="col">
                                                    <label className="text-muted mb-0" >Request</label>
                                                </th>
                                                <th scope="col" style={{ cursor: "pointer" }} onClick={sort("createdAt")}>
                                                    <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Date</label>
                                                    {' '}
                                                    {this.state.field === "createdAt" && this.state.order === 'desc' && (down)}
                                                    {this.state.field === "createdAt" && this.state.order === 'asc' && (up)}
                                                </th>
                                                <th scope="col">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.draft_data.map((item) => (
                                                    <tr key={item.Price} className="white-space-no-wrap">
                                                        <td className="">
                                                            <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                {/* <div className="h-avatar is-medium">
                                                                <img className="avatar rounded" alt="user-icon" src={item.post} />
                                                            </div> */}
                                                                <div className="data-content">
                                                                    <div>
                                                                        <span className="font-weight-bold">{item.title}</span>
                                                                    </div>
                                                                    {/* <p className="m-0 mt-1">
                                                                    {item.des}
                                                                </p> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.target_request}
                                                        </td>
                                                        <td>
                                                            {formatDate(item.createdAt)}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                    <Link to={"/draft-edit?id=" + item.id} className="">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                        </svg>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* <div>
                        {
                            this.state.draft_page > 1 && (
                                <>
                                    <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ draft_page: this.state.draft_page - 1 }, () => this.call())}>
                                        {this.state.draft_page - 1}
                                    </span>
                                    {' '}
                                    {'-'}
                                    {' '}
                                </>
                            )
                        }
                        <span>
                            {this.state.draft_page}
                        </span>
                        {
                            this.state.draft_total > this.state.draft_page * this.state.draft_limit && (
                                <>
                                    {' '}
                                    {'-'}
                                    {' '}
                                    <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ draft_page: this.state.draft_page + 1 }, () => this.call())}>
                                        {this.state.draft_page + 1}
                                    </span>
                                </>

                            )
                        }
                        {' '}
                        <span>
                            ({this.state.draft_total % this.state.draft_limit === 0 ? this.state.draft_total / this.state.draft_limit : Math.floor(this.state.draft_total / this.state.draft_limit) + 1})
                        </span>
                    </div> */}
                </Row>
            </Col>

        )
    }

    render3() {
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        return (
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="font-weight-bold">Drafts</h4>
                    </div>
                    <div className="create-workform">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="modal-post-search d-flex">
                                <Form className="mr-3 position-relative">
                                    <div className="form-group mb-0">
                                        <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Post" onChange={(e) => this.setState({ validated_filterTitle: e.target.value }, () => this.call())} />
                                    </div>
                                </Form>
                                {/* <Link to="/text-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Add Text
                                </Link>
                                <Link to="/text-import" className="btn btn-primary position-relative d-flex align-items-center justify-content-between" style={{ marginLeft: "20px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Import Texts
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Drafts List</h5>
                                </div>
                                <div className="table-responsive">
                                    <table className="table data-table mb-0">
                                        <thead className="table-color-heading">
                                            <tr className="text-light">
                                                <th scope="col" style={{ cursor: "pointer" }} onClick={sort("title")}>
                                                    <label className="text-muted m-0" style={{ cursor: "pointer" }} >Title</label>
                                                    {' '}
                                                    {this.state.field === "title" && this.state.order === 'desc' && (down)}
                                                    {this.state.field === "title" && this.state.order === 'asc' && (up)}
                                                    {/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="Search Post">  */}
                                                </th>
                                                <th scope="col">
                                                    <label className="text-muted mb-0" >Request</label>
                                                </th>
                                                <th scope="col" style={{ cursor: "pointer" }} onClick={sort("createdAt")}>
                                                    <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Date</label>
                                                    {' '}
                                                    {this.state.field === "createdAt" && this.state.order === 'desc' && (down)}
                                                    {this.state.field === "createdAt" && this.state.order === 'asc' && (up)}
                                                </th>
                                                <th scope="col">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.validated_data.map((item) => (
                                                    <tr key={item.Price} className="white-space-no-wrap">
                                                        <td className="">
                                                            <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                {/* <div className="h-avatar is-medium">
                                                                <img className="avatar rounded" alt="user-icon" src={item.post} />
                                                            </div> */}
                                                                <div className="data-content">
                                                                    <div>
                                                                        <span className="font-weight-bold">{item.title}</span>
                                                                    </div>
                                                                    {/* <p className="m-0 mt-1">
                                                                    {item.des}
                                                                </p> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.target_request}
                                                        </td>
                                                        <td>
                                                            {formatDate(item.createdAt)}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                                    <Link to={"/validated-view?id=" + item.id} className="">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                        </svg>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* <div>
                        {
                            this.state.validated_page > 1 && (
                                <>
                                    <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ validated_page: this.state.validated_page - 1 }, () => this.call())}>
                                        {this.state.validated_page - 1}
                                    </span>
                                    {' '}
                                    {'-'}
                                    {' '}
                                </>
                            )
                        }
                        <span>
                            {this.state.validated_page}
                        </span>
                        {
                            this.state.validated_total > this.state.validated_page * this.state.validated_limit && (
                                <>
                                    {' '}
                                    {'-'}
                                    {' '}
                                    <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ validated_page: this.state.validated_page + 1 }, () => this.call())}>
                                        {this.state.validated_page + 1}
                                    </span>
                                </>

                            )
                        }
                        {' '}
                        <span>
                            ({this.state.validated_total % this.state.validated_limit === 0 ? this.state.validated_total / this.state.validated_limit : Math.floor(this.state.validated_total / this.state.validated_limit) + 1})
                        </span>
                    </div> */}
                </Row>
            </Col>

        )
    }

    render() {
        if (!this.state.data || !this.state.draft_data || !this.state.validated_data)
            return <></>

        return (
            <>
                <Container fluid>
                    <Row>
                        <Card.Body className="p-0">
                            <Tab.Container defaultActiveKey="first">
                                <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="first" bsPrefix="font-weight-bold text-uppercase px-5 py-2 " >To assign</Nav.Link></Nav.Item>
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="second" bsPrefix="font-weight-bold text-uppercase px-5 py-2" >In progress</Nav.Link></Nav.Item>
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="3" bsPrefix="font-weight-bold text-uppercase px-5 py-2" >To validate</Nav.Link></Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first" className="fade p-3">
                                        {this.renderFirst()}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" className="fade p-3">
                                        {this.renderSecond()}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="3" className="fade p-3">
                                        {this.render3()}
                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Post;
