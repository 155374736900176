
import { REACT_APP_API_HOSTNAME } from './constants/constants'

const API_URL = REACT_APP_API_HOSTNAME

const check_validation = (email) => {
    const requestHeaders = new Headers()
    requestHeaders.set("Content-Type", "application/json")
    return fetch(API_URL + "/partner/check_validation", {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify({
            email: email,
        })
    }).then(response =>
        response.text().then(text => ({
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            body: text,
        }))
    ).then(({ status, statusText, headers, body }) => {
        if (status === 400) {
            window.location = `/auth/confirm-mail?email=${email}`
            return Promise.resolve(1)
        }
        return Promise.resolve(0)
    });
};

const authProvider = {
    // authentication
    login: ({ email, password }) => {
        check_validation(email).then((code) => {
            if (code === 1)
                return
            const request = new Request(`${API_URL}/partner/authenticate`, {
                method: 'POST',
                body: JSON.stringify({ email, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });
            return fetch(request)
                .then(response => {
                    if (response.status === 400) {
                        window.location = '/auth/confirm-mail?email=' + email
                    }
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(auth => {
                    localStorage.setItem('token', auth.token);
                    localStorage.setItem('auth', JSON.stringify(auth));
                    const redirectTo = '' //new URLSearchParams(window.location.hash.split('#/email').join('')).get("redirectTo")
                    console.log(window.location, { redirectTo })
                    if (redirectTo)
                        setTimeout(() => window.location = redirectTo, 1)
                    else
                        setTimeout(() => window.location = '/', 200)
                })
                .catch(() => {
                    // throw new Error('Network error')
                    window.location = '/auth/sign-in'

                });
        })
    },
    register: ({ email, username, password }) => {
        const requestHeaders = new Headers()
        requestHeaders.set("Content-Type", "application/json")
        fetch(API_URL + "/partner/register", {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify({
                email: email,
                login: username,
                password: password
            })
        }).then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        ).then(({ status, statusText, headers, body }) => {
            window.location = '/auth/sign-in'
        });
    },
    validate: (hash, password) => {
        const requestHeaders = new Headers()
        requestHeaders.set("Content-Type", "application/json")
        fetch(API_URL + "/partner/validate", {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify({
                hash,
                password
            })
        }).then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        ).then(({ status, statusText, headers, body }) => {
            window.location = '/auth/sign-in'
        })
    },
    validation: (email) => {
        const requestHeaders = new Headers()
        requestHeaders.set("Content-Type", "application/json")
        fetch(API_URL + "/partner/resent/registration", {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify({
                email,
            })
        }).then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        ).then(({ status, statusText, headers, body }) => {

        })
    },
    forgottenPassword: (email) => {
        const requestHeaders = new Headers()
        requestHeaders.set("Content-Type", "application/json")
        fetch(API_URL + "/partner/send/forgotten-password", {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify({
                email,
            })
        }).then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        ).then(({ status, statusText, headers, body }) => {
            window.location = '/auth/sign-in'
        });
    },
    changePassword: (hash, password) => {
        const requestHeaders = new Headers()
        requestHeaders.set("Content-Type", "application/json")
        fetch(API_URL + "/partner/forgotten-password/change-password", {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify({
                hash,
                password
            })
        }).then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        ).then(({ status, statusText, headers, body }) => {
            window.location = '/auth/sign-in'
        });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: params => {
        const request = new Request(`${API_URL}/partner/check_authentication`, {
            method: 'POST',
            body: JSON.stringify({ token: localStorage.getItem('token') }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(() => {
            })
            .catch(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                window.location = 'auth/sign-in'
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () => Promise.resolve(),
    // authorization
    getPermissions: params => Promise.resolve(),
}

export default authProvider
